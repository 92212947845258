import moment from "moment";

(() => {
    'use strict'

    const form = document.querySelector('.needs-validation');
    const phoneInput = document.querySelector('input.phoneInput');
    const whatsappInput = document.querySelector('input.whatsappInput');
    const datesInput = document.querySelector('input.datesInput');
    const departureInput = document.querySelector('input.departureInput');

    function setPhoneNumber(value) {
        return value.replace(/[^0-9()+\-, ]/g, '');
    }

    if(phoneInput) {
        phoneInput.addEventListener('input', function() {
            this.value = setPhoneNumber(this.value);
        });
    }

    if(whatsappInput) {
        whatsappInput.addEventListener('input', function() {
            this.value = setPhoneNumber(this.value);
        });
    }

    if(datesInput) {
        $(datesInput).daterangepicker({
            autoUpdateInput: false,
            singleDatePicker: true,
            parentEl: '.scroll-fix',
            minDate: moment().add(3, 'days'),  // 3 days after the current date
            opens: 'left',
            autoApply: true,  // Automatically apply the date when selected
            showDropdowns: true,  // Display the dropdown for selecting year/month
            locale: {
                format: 'DD MMMM YYYY',
                cancelLabel: null,  // Remove the 'Clear' button
            },
            alwaysShowCalendars: true,  // Show the calendars
            linkedCalendars: true,  // Allow showing two months separately
            numberOfMonths: 2,  // Show two months
        });

        $(datesInput).on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD MMMM YYYY'));
            $(departureInput).val(picker.startDate.format('YYYY-MM-DD'));
        });

        $(datesInput).on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            $(departureInput).val('');
        });
    }

    if(form) {
        form.addEventListener('submit', event => {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();

                const qtyInput = form.querySelector('input.qtyInput[name="adult"]');
                if(qtyInput) {
                    const panelDropdown = qtyInput.closest('.panel-dropdown');

                    if(!isNaN(parseInt(qtyInput.value))) {
                        panelDropdown.classList.add('is-invalid');
                    } else if(parseInt(qtyInput.value)<=0) {
                        panelDropdown.classList.add('is-invalid');
                    } else {
                        panelDropdown.classList.remove('is-invalid');
                    }
                }
            }

            form.classList.add('was-validated')
        }, false);
    }
})()
